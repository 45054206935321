Fastweb.Matches = (function($) {

  init = function(current_filter) {
    $('#matches_tabs').foundation('selectTab', $('#' + current_filter), true);
  }

  goToSelectedTab = function() {
  	href = window.location.href;
    current_tab = href.split('#')[1];
    if ( current_tab ) {
      document.cookie = 'matches_tab=' + current_tab;
      $(document).ready(function(){
        $("#" + current_tab + "-label").click();
      });
    }
  }

  sortMatches = function(fetch_path, current_filter) {
    $.ajax({
      'url': fetch_path,
      'data': {"current_filter": current_filter, "order": $('#drop_sort').val(), "page": 1},
      'dataType': 'script'
    });
  }

  moreMenu = function(current_filter) {
    // open the more popup
    $('.more_to.' + current_filter).on('click',function(){
      $(this).siblings('.select_box').toggle();
    })
  }

  renderAd = function renderAd(targetEl, placement_name) {
    const adWrapId = $(targetEl).attr('data-ad-wrap-id');

    if ( $(targetEl).attr('data-rendered-ad') != 'true' ) {
      $.get({
        url: '/awards/ajax_ad_request',
        data: { ad_wrap_id: adWrapId, is_mobile: !!adWrapId.match('small'), placement_name: placement_name },
        dataType: 'script'
      });

      $(targetEl).attr('data-rendered-ad', 'true');
    }
  }

  function renderTableForDirectories(current_filter, award_type) {
    $(`.${award_type}-row.${current_filter} .active_award`).click((e) => {
      e.stopPropagation();
    })
    $(`.${award_type}-row.${current_filter}`).click(function(e) {
      renderScholarshipDescription($(this));
    });
  }

  function renderScholarshipDescription(targetEl) {
    $(targetEl).nextAll('.description_row').slideToggle(40);
    $(targetEl).toggleClass('active');
    $(targetEl).nextAll('tr').toggleClass('active');
  }

  function stopPropagationOnLinksWithinTableRow(current_filter, award_type) {
    $(`.${award_type}-row.${current_filter} .active_award`).click((e) => {
      e.stopPropagation();
    })
    $(`.${award_type}-row.${current_filter} .action_wrap`).click((e) => {
      e.stopPropagation();
    })
    $(`.${award_type}-row.${current_filter} .has-tip`).click((e) => {
      e.stopPropagation();
    })
    $(`.${award_type}-row.${current_filter} .select_box a`).click((e) => {
      e.stopPropagation();
    })
    $(`.${award_type}-row.${current_filter} .select_box .cancel-button`).click((e) => {
      e.stopPropagation();
    })
  }

  matchesTableRender = function(current_filter, award_type) {
    $(document).ready(function() {
      // correct the page structure overflow
      $('.off-canvas-wrapper').addClass('add_select_icons');
      moreMenu(current_filter);

      stopPropagationOnLinksWithinTableRow(current_filter, award_type);
      $(`.${award_type}-row.${current_filter}`).click(function(e) {
        if(e.target.name != 'status') {
          const td = $(this).children().first()[0];
          const adWrapId = $(td).attr('data-ad-wrap-id');
          var placement_name;
          if (!!adWrapId.match('small')) {
            placement_name = 'MATCHES_TABLE_DESC_MOBILE';
          } else {
            placement_name = 'MATCHES_TABLE_DESC_DESKTOP';
          }
          renderAd(td, placement_name);
          renderScholarshipDescription($(this));
        }
        return false;
      });
    })
  }

  return {
    init: init,
    goToSelectedTab: goToSelectedTab,
    sortMatches: sortMatches,
    matchesTableRender: matchesTableRender,
    moreMenu: moreMenu,
    renderAd: renderAd,
    renderScholarshipDescription: renderScholarshipDescription,
    renderTableForDirectories: renderTableForDirectories
  }

})(jQuery);
